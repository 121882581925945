// extracted by mini-css-extract-plugin
export var lbContainerOuter = "l_ph";
export var lbContainerInner = "l_pj";
export var movingForwards = "l_pk";
export var movingForwardsOther = "l_pl";
export var movingBackwards = "l_pm";
export var movingBackwardsOther = "l_pn";
export var lbImage = "l_pp";
export var lbOtherImage = "l_pq";
export var prevButton = "l_pr";
export var nextButton = "l_ps";
export var closing = "l_pt";
export var appear = "l_pv";