// extracted by mini-css-extract-plugin
export var alignLeft = "v_rd d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_rf d_fr d_bH d_dx";
export var leftWrapper = "v_sm d_bz d_bD d_bJ";
export var wrapper = "v_sn d_w d_cc";
export var imgWrapper = "v_sp";
export var caption = "v_sq";
export var text = "v_pc d_cs";
export var instagramGuttersImage = "v_g2 d_g2 d_cv d_K d_cv";
export var instagramNoGuttersImage = "v_g1 d_g1 d_cD d_K d_cD";
export var instagramContainerFull = "v_sr d_dT d_w";
export var instagramContainer = "v_ss d_dW";
export var content = "v_st d_cc";
export var errorContainer = "v_sv";
export var instagramImg = "v_sw d_w d_H d_dY d_bd d_0 d_8 d_7 d_4 d_9 d_bR";
export var instagramImageWrapper = "v_sx d_fg d_Z";
export var instagramBtnWrapper = "v_g9 d_g9 d_0 d_bC d_bP d_bX d_dw d_bq";
export var instagramBtnWrapperLeft = "v_sy d_d2 d_d1 d_w d_bz d_bG d_c7";
export var instagramBtnWrapperCenter = "v_sz d_d3 d_d1 d_w d_bz d_bD d_c7";
export var instagramBtnWrapperRight = "v_sB d_d4 d_d1 d_w d_bz d_bH d_c7";